@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@queries' as *;

.drop-options {
  @include mainContentStyles;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
    gap: 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .header-label {
      span[id='label-icon'] {
        color: #7832ea;
      }
    }

    .header-restart-btn {
      @include singleFixedSize(32px);

      @include min-428-break {
        @include singleFixedSize(40px);
      }

      &--icon {
        @include singleFixedSize(16px);

        @include min-428-break {
          @include singleFixedSize(18px);
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include min-428-break {
      gap: 10px;
    }
  }
}
