@use '@content' as *;
@use '@queries' as *;
@use '@fixed-size' as *;

.background-wrapper {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  background-image: url('/assets/legal/illustration-legal-desktop.webp');
  background-size: cover;
  background-position: top;
  @include fixedSize(100%, 213px);

  @include min-1440-break {
    @include fixedSize(100%, 226px);
  }

  @include min-1920-break {
    @include fixedSize(100%, 233px);
  }
}
