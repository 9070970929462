@use '@fixed-size' as *;
@use '@queries' as *;
@use '@content' as *;

.egg-wrapper {
  @include mainContentStyles;
  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }

  .item {
    position: relative;
    overflow: hidden;
    display: flex;
    aspect-ratio: 1 /1;
    border-radius: var(--cornerradius10);
    outline: 2px solid var(--cst-cards-item-block-outline);
    outline-offset: -2px;
    @include fixedSize(100%, 100%);

    @include min-428-break {
      border-radius: var(--cornerradius14);
    }

    @include min-744-break {
      border-radius: var(--cornerradius16);
    }

    .item-background {
      position: absolute;
      @include fixedSize(100%, 100%);
      aspect-ratio: 1/1;
      color: var(--global-blue600);
    }

    .item-shadow {
      position: absolute;
      bottom: 13%;
      left: 0;
      right: 0;
      margin-inline: auto;
      display: flex;
      background: #d9d9d9;
      scale: 0.379;
      aspect-ratio: 110 / 30;
      border-radius: 50%;

      @include min-744-break {
        scale: 0.32;
        bottom: 17%;
      }
    }

    .item-egg {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      margin-inline: auto;
      aspect-ratio: 134 / 140;
      z-index: 2;
      opacity: 0;
      scale: 0.478;
      visibility: hidden;
      -webkit-transition:
        visibility 1.5s ease-in-out,
        opacity 1.5s ease-in-out;
      -moz-transition:
        visibility 1.5s ease-in-out,
        opacity 1.5s ease-in-out;
      -o-transition:
        visibility 1.5s ease-in-out,
        opacity 1.5s ease-in-out;

      &.show {
        visibility: visible;
        opacity: 1;
        animation: scale 2s ease-in-out;
      }

      &.hide {
        display: none;
      }

      @keyframes scale {
        0% {
          scale: 0;
        }
        100% {
          scale: 0.478;
        }
      }

      @include fixedSize(100%, 100%);

      @include min-744-break {
        scale: 0.4;
      }
    }
  }
}
