@use '@content' as *;
@use '@queries' as *;
@use '@typography' as *;

.container__legal-info {
  display: flex;
  flex-direction: column;
  background: var(--global-white);
  border-radius: var(--cornerradius12);
  box-shadow: inset 0 0 0 1px var(--cst-legal-textbox-outline);
  max-height: 344px;
  overflow-y: hidden;
  margin-top: 20px;
  padding: 0px 6px 0px 0px;
  max-width: 936px !important;
  z-index: 1;

  @include min-1440-break {
    margin-top: 32px;
    height: 435px;
    max-height: 435px;
    max-width: 1131px !important;
  }

  @include min-1920-break {
    height: 540px;
    max-height: 540px;
    max-width: 1400px !important;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 6px 16px 16px;
    overflow: auto;
    max-height: 344px;

    @include min-1440-break {
      padding: 20px 8px 20px 20px;
      height: 435px;
      max-height: 435px;
    }

    @include min-1920-break {
      height: 540px;
      max-height: 540px;
    }
  }
}

.legal-text {
  font-family: Inter, sans-serif;
  max-height: max-content;
  padding-right: 16px;
  margin-bottom: 16px;
  @include typography-s;
  @include weight-regular;
  color: var(--cst-legal-text);

  @include min-1440-break {
    @include typography-base;
    margin-bottom: 0px;
  }
}
