@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;
@use '@content' as *;

.smash-block {
  @include mainContentStyles;
  display: flex;
  gap: 8px;
  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }

  .smash-btn {
    @include buttonSizes('42', '46', '52', '52', '52', '52');

    span[id='button-span-text'] {
      @include typography-base;
      @include weight-semi-bold;
    }

    &--icon {
      @include singleFixedSize(18px);

      @include min-428-break {
        @include singleFixedSize(20px);
      }

      @include min-744-break {
        @include singleFixedSize(22px);
      }
    }
  }
}
