@use '@/app/queries' as *;
@use '@/shared/styles/fixed' as *;
@use '@typography' as *;

.container {
  position: relative;
  width: 100%;
}

.backButton {
  padding: 8px 10px;
  border-radius: var(--cornerradius10) !important;
  @include singleFixedSize(42px);
  span {
    @include singleFixedSize(18px);
  }
  @include min-428-break {
    border-radius: var(--cornerradius12) !important;
    padding: 12.5px 13px;
    span {
      @include singleFixedSize(22px);
    }
    @include singleFixedSize(52px);
  }
}

.main_wrapper {
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 10px;
  height: 42px;
  width: 100%;
  border: 2px solid var(--cst-inputauth-outline-default);
  border-radius: var(--cornerradius10);
  gap: 8px;

  p {
    @include typography-s;
  }

  @include min-428-break {
    height: 52px;
    padding: 0 12px;
    gap: 12px;
    border-radius: var(--cornerradius12);
    p {
      @include typography-base;
    }
  }

  &:focus-within {
    border: 2px solid var(--cst-inputauth-outline-intype);
    //box-shadow: 0 0 0 4px #6941C633;
  }

  .input_wrapper {
    flex: 1;
    height: 95%;
  }

  .input_field {
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    font-family: Inter, sans-serif;
    color: var(--cst-input-search-text-typing);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    background-color: white;

    @include min-428-break {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.trigger {
  margin-bottom: 0;
}

.sheetHeader {
  height: 0;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
  padding: 10px 14px;
  box-shadow: var(--elevation-1);
  @include min-428-break {
    padding: 14px;
  }
  @include min-744-break {
    padding: 14px 28px;
  }
}

.sheetContainer {
  height: calc(100% - env(safe-area-inset-top)) !important;
  border-radius: 0 !important;
  overflow-y: hidden;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 14px;

  @include min-428-break {
    gap: 14px;
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }
}

.tabs_container {
  max-width: 100% !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  gap: 6px !important;

  p {
    color: #606060;
    @include weight-semi-bold;
    @include typography-s;
  }

  button {
    padding: 8px 18px;
    height: 34px;
    border-radius: var(--cornerradius8);
  }

  @include min-428-break {
    gap: 8px !important;
    p {
      @include typography-base;
    }
    button {
      padding: 10px 20px;
      height: 41px;
      border-radius: var(--cornerradius10);
    }
  }
}

.title {
  @include typography-s;
  @include min-428-break {
    @include typography-base;
  }
}

.search {
  &-block {
    display: flex;
    flex-direction: column;
    gap: 14px;
    @include min-428-break {
      gap: 18px;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    gap: 5.5px;
    @include min-428-break {
      gap: 7.5px;
    }
  }
  &-item {
    padding: 8px !important;

    &:active,
    &:hover {
      background-color: var(--cst-dropitem-background-default);
      div > p {
        color: var(--cst-dropitem-text-default);
      }
    }

    span {
      @include singleFixedSize(20px);
    }

    p {
      @include typography-s;
    }

    @include min-428-break {
      padding: 10px 0 !important;
      span {
        @include singleFixedSize(24px);
      }
      p {
        @include typography-base;
      }
    }
  }
}

.search_icon {
  @include singleFixedSize(20px);
  @include min-428-break {
    @include singleFixedSize(24px);
  }
}

.search_text {
  @include typography-s;
  @include weight-medium;

  @include min-428-break {
    @include typography-base;
  }
}

.close_btn {
  @include singleFixedSize(20px);
  @include min-428-break {
    @include singleFixedSize(24px);
  }
}
