@use '@content' as *;
@use '@queries' as *;
@use '@typography' as *;

.container {
  &__top-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 936px !important;

    @include min-1440-break {
      gap: 32px;
      max-width: 1131px !important;
    }

    @include min-1920-break {
      max-width: 1400px !important;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: center;
    z-index: 1;

    @include min-1440-break {
      gap: 12px;
    }
  }

  &__tabs {
    display: flex;
    min-width: 100%;
    gap: 0;
    z-index: 1;
    border-radius: var(--cornerradius8);
    background: var(--cst-live-feed-background-tabs, #f6f6f6);

    > a {
      text-decoration: none;
      min-width: 33.33%;
      button {
        min-width: 100%;

        p {
          @include typography-base;
          @include weight-medium;

          @include min-1440-break {
            @include typography-m;
          }
        }
      }
    }
  }
}

.title {
  color: var(--cst-legal-title);
  @include typography-l;
  @include weight-semi-bold;

  @include min-1440-break {
    @include typography-xl;
  }
}

.subtitle {
  color: var(--cst-legal-subtitle);
  @include typography-s;
  @include weight-medium;

  @include min-1440-break {
    @include typography-base;
  }
}
